import { dispatches } from 'lib/event-dispatcher';
import Logger from 'lib/logger';
import PlayerController from 'lib/player-controller';
import config from 'config';
import messageTypes from 'constants/message-types';

const logger = new Logger('CAST', { background: 'green', color: 'white' });


/**
 * Interface to Google's Cast API
 */
@dispatches('cast')
class CastMessenger {
  /**
   * @param {object} cast - Global `cast` object provided by Google's Cast API JS
   */
  constructor(cast) {
    this.cast = cast;
  }

  _eventDetail(detail) {
    const { senderId } = detail;
    return {
      ...detail,
      reply: (type, detail) => this.sendMessage(senderId, type, detail)
    };
  }

  /**
   * Start Cast API
   *
   * Sets up listener for custom channel and notifies Sender app that
   * app has started.
   */
  connect() {
    this.customChannel = config.customCastChannel;
    this.context = this.cast.framework.CastReceiverContext.getInstance();

    this.context.addCustomMessageListener(this.customChannel, (event) => {
      const senderId = event.senderId;
      const { type, ...detail } = event.data;
      this.receiveMessage(type, {
        senderId,
        ...detail
      });
    });

    this.context.addEventListener(this.cast.framework.system.EventType.SENDER_CONNECTED, (detail) => {
      // Sending responses to SENDER_CONNECTED too quickly after connect is
      // leading to the message being lost. Adding delay to prevent this.
      // From experimentation, less than 1 second isn't enough delay
      setTimeout(() => this.dispatchEvent(messageTypes.senderConnected, this._eventDetail(detail)), 1000);
    });

    this.context.addEventListener(this.cast.framework.system.EventType.SENDER_DISCONNECTED, (detail) => {
      this.dispatchEvent(messageTypes.senderDisconnected, this._eventDetail(detail));
    });

    this.context.addEventListener(this.cast.framework.system.EventType.SHUTDOWN, (detail) => {
      this.dispatchEvent(messageTypes.shutdown, this._eventDetail(detail));
    });

    this.playerManager = this.context.getPlayerManager();

    this.playerManager.setPlayerFactory(this._createPlayerController);

    this.playerManager.setMessageInterceptor(
      this.cast.framework.messages.MessageType.PAUSE,
      this._pauseInterceptor);

    this.playerManager.setMessageInterceptor(
      this.cast.framework.messages.MessageType.SEEK,
      this._seekInterceptor);

    this.playerManager.setMessageInterceptor(
      this.cast.framework.messages.MessageType.MEDIA_STATUS,
      this._mediaStatusInterceptor);

    this.context.start({
      disableIdleTimeout: true // Must be disabled since we aren't using Cast API media player
    });
  }

  /**
   * Create PlayerController for PlayerManager
   *
   * This is used as the PlayerFactory for the CAF PlayerManager
   *
   * @param {object} contentConfig - Content config from CAF
   * @return {PlayerController}
   */
  _createPlayerController = async (contentConfig) => {
    logger.log('Creating PlayerController', contentConfig);

    this.playerController = new PlayerController({
      cast: this.cast,
      playerManager: this.playerManager,
      contentConfig
    });
    logger.log('PlayerController CREATED');

    this.dispatchEvent(messageTypes.controllerCreated, { controller: this.playerController });

    return this.playerController;
  };

  /**
   * Pause message interceptor for PlayerManager
   *
   * @param {object} request - Pause request from CAF
   * @return {null|object} - null to block request, or request object
   */
  _pauseInterceptor = (request) => {
    if (this.playerController.pauseDisabled) {
      logger.log('Inhibiting pause');
      return null;
    }

    return request;
  };

  /**
   * Seek message interceptor for PlayerManager
   *
   * @param {object} request - Seek request from CAF
   * @return {null|object} - null to block request, or request object
   */
  _seekInterceptor = (request) => {
    if (this.playerController.seekDisabled || !this.playerController.canSeekToTime(request.currentTime)) {
      logger.log('Inhibiting seek');
      return null;
    }
    if (request.currentTime) {
      request.currentTime = Math.max(request.currentTime, 1);
    }
    return request;
  };

  /**
   * Media status interceptor for PlayerManager
   *
   * @param {object} data - Data for the outgoing MEDIA_STATUS message
   * @return {null|object} - null to block message, or data object
   */
  _mediaStatusInterceptor = (data) => {
    if (this.playerController) {
      return {
        customData: this.playerController.statusCustomData,
        ...data
      };
    }

    return data;
  };

  /**
   * Shutdown Cast API (and Receiver app)
   */
  disconnect() {
    this.context.stop();
  }

  /**
   * Reset the idle timeout
   *
   * Does nothing if the timeout isn't currently enabled
   */
  resetIdleTimeout() {
    if (!this.idleTimer) {
      return;
    }

    this.disableIdleTimeout();
    this.enableIdleTimeout();
  }

  /**
   * Handle a message sent from Sender app
   *
   * @param {string} type - Message type
   * @param {object} detail - Extra data included in message
   */
  receiveMessage(type, detail) {
    logger.log('Received %c%s%c: %O', 'font-weight: bold', type, '', detail);
    this.dispatchEvent(type, this._eventDetail(detail));
  }

  /**
   * Send a message to a specific Sender app
   *
   * @param {string} senderId - ID of target Sender app
   * @param {string} type - Message type
   * @param {object} detail - Extra data to send with message
   */
  sendMessage(senderId, type, detail) {
    logger.log('Sending %c%s%c to %s: %O', 'font-weight: bold', type, '', senderId, detail);
    if (this.context && this.context.sendCustomMessage) {
      this.context.sendCustomMessage(this.customChannel, senderId, { type, ...detail });
    }
  }

  /**
   * Send a message to all Sender apps
   *
   * @param {string} type - Message type
   * @param {object} detail - Extra data to send with message
   */
  broadcastMessage(type, detail) {
    if (type !== messageTypes.mediaProgress) { // Don't spam console with MEDIA_PROGRESS
      logger.log('Broadcasting %c%s%c: %O', 'font-weight: bold', type, '', detail);
    }
    if (this.context && this.context.sendCustomMessage) {
      this.context.sendCustomMessage(this.customChannel, undefined, { type, ...detail });
    }
  }

  createLoadRequest({ sessionId, watchable, programId, channelId }) {
    if (!sessionId && this.playerController && this.playerController.contentConfig) {
      sessionId = this.playerController.contentConfig.media.customData.sessionId;
    }

    const request = new this.cast.framework.messages.LoadRequestData();
    request.media.customData = { sessionId, programId, channelId };

    if (watchable) {
      request.media.contentType = 'application/vnd.comcast.watchable';
      request.media.contentId = watchable.selfLinkUrl;
    } else {
      request.media.contentId = programId || channelId;
    }

    return request;
  }
}

/**
 * Handler for Cast message events
 *
 * The `event.detail.reply` function calls {@link CastMessenger#sendMessage}
 * with the senderId parameter already provided
 *
 * @callback CastMessenger~messageHandler
 * @param {object} event - CustomEvent object
 * @param {object} event.detail - Extra data sent in the Cast message
 * @param {function(type, detail)} event.detail.reply - Send a response message
 */

export default CastMessenger;
