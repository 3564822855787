import api from 'api';
import featuresTypes from 'constants/xvp-features';
import WatchableModel from 'model/watchable';
import CreativeWorkModel from 'model/creative-work';
import ChannelModel from 'model/channel';
import { senderDebugger } from 'lib/debug/sender-receiver-debug';
import watchableTypes from 'constants/watchable-types';
import XVP from '../lib/xvp';
import SplunkLogger from 'lib/telemetry/splunk-logger';

let listingCache = {};

const getChannel = async (resource) => {
  if (resource.hasAction && resource.hasAction('channel')) {
    const channelLink = resource.getFirstAction('channel').getRawActionUrl();
    return ChannelModel.loadFromSelfLink(channelLink);
  }

  return ChannelModel.loadFromChannelId(resource);
};
/**
 * Listing object
 *
 * @hideconstructor
 */
class ListingModel extends WatchableModel {
  static async _propertiesFromResource(resource) {
    const props = resource.getProps ? resource.getProps() : resource;
    const [inheritedProps, channel] = await Promise.all([
      super._propertiesFromResource(resource),
      getChannel(resource)
    ]);

    const result = {
      ...inheritedProps,
      channel,
      listingId: props.listingId,
      entityId: props.entityId || (props.program && props.program.programId),
      startTime: Number(props.startTime),
      endTime: Number(props.endTime),
      derivedTitle: props.title,
      isHD: props.isHD,
      isSubjectToBlackout: props.isSubjectToBlackout,
      type: props.type,
      rating: (channel && channel.enforceParentalControlsOnChannel) ?
        channel.rating : props['contentRating/detailed'] || props.rating,
      isAdult: props.isAdult
    };

    return result;
  }

  /**
   * Build listing from Hypergard resource
   *
   * @param {object} resource - Hypergard resource
   * @return {ListingModel}
   */
  static async fromResource(resource) {
    const listingId = resource.getProp ? resource.getProp('listingId') : resource.listingId;

    if (!listingCache[listingId]) {
      listingCache[listingId] = new ListingModel(await ListingModel._propertiesFromResource(resource));
    }

    return listingCache[listingId];
  }

  /**
   * Reset caches
   *
   * Resets the listing cache
   */

  static resetCache() {
    listingCache = {};
  }

  /**
   * Load listing details and update listing model
   */
  async loadDetails() {
    senderDebugger.sendDebugMessage('[LISTING][Load Details] 1 Started:', {
      detailsLoaded: this.detailsLoaded,
      thisResource: !!this._resource
    });
    if (this.detailsLoaded) {
      return true;
    }
    const programProps = this._resource && this._resource.getProps ? this._resource.getProps() :
      this._resource && this._resource.program || this.program;

    if (XVP.getFeature(featuresTypes.xvpTVGrid)) {
      Object.assign(this, {
        creativeWork: await CreativeWorkModel.fromResource({
          ...programProps,
          entityId: programProps.programId,
          duration: this.duration,
          name: programProps.programType === 'sportingEvent' && programProps.sportsSubtitle
            ? `${programProps.title} - ${programProps.sportsSubtitle}`
            : programProps.title
        })
      });
    } else {
      const response = await api.send({
        endpoint: 'getTvListingDetail',
        params: {
          listingId: this.listingId,
          channelId: this.channel.channelId
        }
      }).catch((error) => {
        senderDebugger.debugErrorMessage('[XTVAPI][LISTING][Load Details] ERROR - Cannot load getTvListingDetail this.entityId', {
          error: error,
          listingId: this.listingId,
          channelId: this.channel.channelId
        });

        SplunkLogger.onError({ error: (error.xhr && error.xhr.xtv) || error.xhr || error });
        throw error;
      });
      const resource = response.resource;
      Object.assign(this, {
        creativeWork: await CreativeWorkModel.fromResource(resource.getEmbedded('encodesCreativeWork'))
      });
    }
    this.detailsLoaded = true;
  }

  async loadCreativeWork() {
    // loadDetails existed first, then loadCreativeWork was added to Watchable
    return this.loadDetails();
  }
}

WatchableModel.addType(ListingModel, ({ _type }) =>
  [watchableTypes.Listing, watchableTypes.ListingDetail].includes(_type));

export default ListingModel;
