/**
 * Constants for xvp migration features sent with ADS features call
 *
 * @namespace constants.featuresTypes
 */

export default {
  xvpHeartbeats: 'xvpHeartbeats',
  xvpResumepoints: 'xvpResumepoints',
  xvpSession: 'xvpSession',
  xvpTVGrid: 'xvpTVGrid'
};
