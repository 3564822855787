/**
 * XVP API URLS
 * See docs: https://internal-xvp-docs-staging.r53.aae.comcast.net/APIs/
 */
export const XVP_API_URLS = {
  DISCO: {
    dev: 'https://disco-dev.exp.xvp.na-1.xcal.tv',
    staging: 'https://disco-stg.exp.xvp.na-1.xcal.tv',
    prod: 'https://disco.exp.xvp.na-1.xcal.tv'
  },
  LINEAR: {
    dev: 'https://linear-dev.exp.xvp.na-1.xcal.tv',
    staging: 'https://linear-stg.exp.xvp.na-1.xcal.tv',
    prod: 'https://linear.exp.xvp.na-1.xcal.tv'
  },
  LINEAR_CDN: {
    dev: 'https://linear-dev.cdn.exp.xvp.na-1.xcal.tv',
    staging: 'https://linear-stg.cdn.exp.xvp.na-1.xcal.tv',
    prod: 'https://linear.cdn.exp.xvp.na-1.xcal.tv'
  },
  PLAYBACK: {
    dev: 'https://playback-dev.exp.xvp.na-1.xcal.tv',
    staging: 'https://playback-stg.exp.xvp.na-1.xcal.tv',
    prod: 'https://playback.exp.xvp.na-1.xcal.tv'
  },
  RIGHTS: {
    dev: 'https://rights-dev.exp.xvp.na-1.xcal.tv',
    staging: 'https://rights-stg.exp.xvp.na-1.xcal.tv',
    prod: 'https://rights.exp.xvp.na-1.xcal.tv'
  },
  SESSION: {
    dev: 'https://session-dev.exp.xvp.na-1.xcal.tv',
    staging: 'https://session-stg.exp.xvp.na-1.xcal.tv',
    prod: 'https://session.exp.xvp.na-1.xcal.tv'
  }
};

export const XVP_API_CONFIGS = {
  /**
   * LINEAR
   */
  /** get channels for the existing session */
  getChannelsByCacheKey: {
    serviceName: 'LINEAR_CDN',
    path: '/v2/partners/{partnerId}/channels',
    method: 'GET'
  },
  /** get listings by stationId for the given date */
  getListingsByDate: {
    serviceName: 'LINEAR_CDN',
    path: '/v2/partners/{partnerId}/stations/{stationId}/listings',
    method: 'GET'
  },
  /** get the local channels available for the current session */
  getLocalTvGridChannels: {
    serviceName: 'LINEAR',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/devices/{deviceId}/channels/local',
    method: 'GET'
  },

  /**
   * DISCO
   */
  /** get watch options in the sorted order of best ways to watch */
  getBestWaysToWatch: {
    serviceName: 'DISCO',
    path: '/v1/partners/{partnerId}/programs/{programId}/bestWaysToWatch',
    method: 'GET'
  },

  /**
   * PLAYBACK
   */
  // ** get all resume points for given user */
  getResumePoints: {
    serviceName: 'PLAYBACK',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/resumePoints',
    method: 'GET'
  },
  // ** save resume point given a mediaId, programId and player position */
  createUpdateResumePoint: {
    serviceName: 'PLAYBACK',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/resumePoints/{entityType}/{entityId}',
    method: 'PUT'
  },
  // ** delete resumepoint given a mediaId and programId
  deleteResumePoint: {
    serviceName: 'PLAYBACK',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/resumePoints/{entityType}/{entityId}',
    method: 'DELETE'
  },

  /**
   * RIGHTS
   */
  /** start/ping heartbeat for t6 linear */
  putHeartbeatLinear: {
    serviceName: 'RIGHTS',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/devices/{deviceId}/linear/{streamId}/heartbeat',
    method: 'PUT'
  },
  /** stop heartbeat checks for t6 linear */
  deleteHeartbeatLinear: {
    serviceName: 'RIGHTS',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/devices/{deviceId}/linear/{streamId}/heartbeat',
    method: 'DELETE'
  },
  /** start/ping heartbeat for tve linear */
  putHeartbeatTVELinear: {
    serviceName: 'RIGHTS',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/devices/{deviceId}/linear/tve/{streamId}/heartbeat',
    method: 'PUT'
  },
  /** stop heartbeat checks for tve linear */
  deleteHeartbeatTVELinear: {
    serviceName: 'RIGHTS',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/devices/{deviceId}/linear/tve/{streamId}/heartbeat',
    method: 'DELETE'
  },
  /** start/ping heartbeat for t6 vod */
  putHeartbeatVod: {
    serviceName: 'RIGHTS',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/devices/{deviceId}/vod/{mediaGuid}/heartbeat',
    method: 'PUT'
  },
  /** stop heartbeat checks for t6 vod */
  deleteHeartbeatVod: {
    serviceName: 'RIGHTS',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/devices/{deviceId}/vod/{mediaGuid}/heartbeat',
    method: 'DELETE'
  },
  /** start/ping heartbeat for tve vod */
  putHeartbeatTveVod: {
    serviceName: 'RIGHTS',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/devices/{deviceId}/vod/tve/{mediaGuid}/heartbeat',
    method: 'PUT'
  },
  /** stop heartbeat checks for tve vod */
  deleteHeartbeatTveVod: {
    serviceName: 'RIGHTS',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/devices/{deviceId}/vod/tve/{mediaGuid}/heartbeat',
    method: 'DELETE'
  },
  /** start/ping heartbeat for purchased vod */
  putHeartbeatPurchase: {
    serviceName: 'RIGHTS',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/devices/{deviceId}/vod/purchased/{mediaGuid}/heartbeat',
    method: 'PUT'
  },
  /** stop heartbeat checks for purchased vod */
  deleteHeartbeatPurchase: {
    serviceName: 'RIGHTS',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/devices/{deviceId}/vod/purchased/{mediaGuid}/heartbeat',
    method: 'DELETE'
  },
  /** start/ping heartbeat for dvr */
  putHeartbeatRecording: {
    serviceName: 'RIGHTS',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/devices/{deviceId}/recording/{recordingId}/heartbeat',
    method: 'PUT'
  },
  /** stop heartbeat checks for dvr */
  deleteHeartbeatRecording: {
    serviceName: 'RIGHTS',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/devices/{deviceId}/recording/{recordingId}/heartbeat',
    method: 'DELETE'
  },
  /** start/ping heartbeat for tve recording */
  putHeartbeatTveRecording: {
    serviceName: 'RIGHTS',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/devices/{deviceId}/recording/tve/{recordingId}/heartbeat',
    method: 'PUT'
  },
  /** request additional auth data for external tve linear stream */
  startLinearTveExternal: {
    serviceName: 'RIGHTS',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/devices/{deviceId}/linear/tve/{streamId}/external',
    method: 'POST'
  },
  /** request additional auth data for external tve vod stream */
  startVodTveExternal: {
    serviceName: 'RIGHTS',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/devices/{deviceId}/vod/tve/{mediaLocator}/external',
    method: 'POST'
  },
  /** ip-based geolocation check for tve linear */
  canStreamTve: {
    serviceName: 'RIGHTS',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/devices/{deviceId}/linear/tve/{streamId}/canStream',
    method: 'POST'
  },

  /**
   * SESSION
  */
  getSession: {
    serviceName: 'SESSION',
    path: '/v1/partners/{partnerId}/accounts/{accountId}/devices/{deviceId}/session',
    method: 'GET'
  }
};
