/**
 * @module resumePoints
 */
import { dispatches } from 'lib/event-dispatcher';
import Logger from 'lib/logger';
import api from 'api';
import featuresTypes from 'constants/xvp-features';
import XVP from 'lib/xvp';
import { senderDebugger } from './lib/debug/sender-receiver-debug';

const updateEndpoint = 'updateResumePoint';
const getEndpoint = 'getResumePoints';

const logger = new Logger('RESUME');

/* eslint-disable valid-jsdoc */
/**
 *  XTVAPI Resume point
 * @type {Object}
 */
/* eslint-enable valid-jsdoc */
@dispatches('resumePoints')
class ResumePoints {
  resumePoints = {};

  /**
   * Fetch all resume points for user
   * @return {Promise} xtvapi get resume points promise
   */
  getAllResumePoints = async () => {
    if (XVP.getFeature(featuresTypes.xvpResumepoints)) {
      try {
        senderDebugger.debugResumePointMessage('[XVP] getAllResumePoints: ', { xvpEndpoint: getEndpoint });
        await XVP.send({
          endPoint: getEndpoint
        }).then( (response) => this.parseXVPResumePoints(response) );
      } catch (error) {
        senderDebugger.debugErrorMessage('[RESUME-POINT][XVP] ERROR: ', error);

        // TODO make a constants file for resume types
        this.dispatchEvent('failedGetResumePoint', { error });
      }
    } else {
      senderDebugger.debugResumePointMessage('[XTVAPI] getAllResumePoints: ', { xvpEndpoint: getEndpoint });
      try {
        return await api.send({
          endpoint: getEndpoint,
          params: {
            maxDaysOld: 60 // TODO : some dynamic getter for max days
          }
        }).then( (resolve) => this.parseResumePoints(resolve) );
      } catch (error) {
        const xtvError = error.xhr.xtv;
        senderDebugger.debugErrorMessage('[RESUME-POINT][XTVAPI] ERROR: ', { error: error,
          xtvError: xtvError });
        // TODO make a constants file for resume types
        this.dispatchEvent('failedGetResumePoint', { xtvError });
      }
    }
  }
  /**
  * get single resume point given a mediaId
  * @param  {Object} watcahable
  * @param  {string}  programId entity.programId
  * @return {number} resume point for asset
  */
  async getResumePoint({ watchable, programId }) {
    let key = '';

    if (!watchable ) {
      senderDebugger.debugResumePointMessage('[getResumePoint] WARN: watchable not provided. Unable to fetch resume point');
      logger.warn('ResumePoint: watchable not provided. Unable to fetch resume point');
      return 0;
    }
    try {
      senderDebugger.debugResumePointMessage('[getResumePoint]',
        { programId_point: programId });
      if (Object.keys(this.resumePoints).length === 0 ) {
        await this.getAllResumePoints();
      }
    } catch (error) {
      senderDebugger.debugErrorMessage('[RESUME-POINT] getResumePoint Fetching Resume Points failed ERROR: ', error);
      logger.error('Fetching Resume Points failed', error);
      return 0;
    }

    key = this._getResumePointKey({ watchable, programId });

    if (!this.resumePoints[key]) {
      return 0;
    }

    // position is for xtvapi, progressMillis is for xvp
    return this.resumePoints[key].position || this.resumePoints[key].progressMillis || 0;
  }

  /**
  * save resume point given a mediaid, programid and player position
  * @param  {string}  mediaId   watchable.mediaId
  * @param  {string}  programId entity.programId
  * @param  {number}  progress  player position to save
  * @return {Promise}
  */
  updateResumePoint = async ({ watchable, mediaId, programId, progress, deviceId }) => {
    const key = this._getResumePointKey({ watchable, programId, mediaId });

    if (!this.resumePoints[key]) {
      this.resumePoints[key] = {};
    }

    this.resumePoints[key].position = progress;

    if (XVP.getFeature(featuresTypes.xvpResumepoints)) {
      try {
        const xvpOptions = {
          endPoint: 'createUpdateResumePoint',
          pathParams: {
            entityType: watchable.isRecording() ? 'dvr' :
              ((watchable.isTve() || watchable.hasTveContextType()) ? 'tve' : 'Program'),
            entityId: watchable.isRecording() ? watchable.id :
              ((watchable.isTve() || watchable.hasTveContextType()) ? watchable.mediaGuid :
                (watchable.programId || (watchable.creativeWork || {}).programId))
          },
          body: {
            ownerReference: 'xrn:subscriber:device:' + deviceId,
            mediaAccountName: watchable.isRecording() ? '' : watchable.accountName,
            mediaGuid: watchable.isRecording() ? '' : watchable.mediaGuid,
            progressMillis: Math.floor(progress)
          }
        };
        senderDebugger.debugResumePointMessage('[XVP][updateResumePoint] ',
          { options: xvpOptions });
        return await XVP.send(xvpOptions);
      } catch (error) {
        // TODO make a constants file for resume types
        senderDebugger.debugErrorMessage('[RESUME_POINT][XVP] updateResumePoint ERROR: ', error);
        this.dispatchEvent('failedUpdateResumePoint', { error });
      }
    } else {
      try {
        const options = {
          endpoint: updateEndpoint,
          params: {
            mediaId: mediaId,
            programId: programId,
            progress: Math.floor(progress)
          }
        };
        senderDebugger.debugResumePointMessage('[XVP][getResumePoint] ',
          { resumePointOptions: options });
        return await api.send(options);
      } catch (error) {
        const xtvError = error.xhr.xtv;
        // TODO make a constants file for resume types
        this.dispatchEvent('failedUpdateResumePoint', { xtvError });
      }
    }
  }

  parseResumePoints({ resource }) {
    if (resource.getEmbedded('resumePointGroups')) {
      const rps = resource.getEmbedded('resumePointGroups').map(function(item) {
        return item.getEmbedded('lastUpdatedResumePoint').getProps();
      });
      this.resumePoints = this._createMap(rps);
    }
  }

  parseXVPResumePoints(resource) {
    if (resource) {
      const updatedResumePoints = {};
      let updated = false;
      senderDebugger.debugResumePointMessage('[XVP][parseXVPResumePoints] ',
        { resumePointResource: resource });
      resource.forEach((rp) => {
        if ((this.resumePoints[rp.entityId] || {}).updated !== rp.updated) {
          updated = true;
        }
        updatedResumePoints[rp.entityId] = rp;
      });

      if (updated) {
        logger.log('RESUME POINT NEEDED UPDATING!!');
        // TODO dispatch event or something if anyone cares this updated
      }
      this.resumePoints = updatedResumePoints;
    }
  }

  _createMap(rps) {
    const updatedResumePoints = {};
    let updated = false;

    rps.forEach((rp) => {
      if ((this.resumePoints[rp.mediaId] || {}).dateUpdated !== rp.dateUpdated) {
        updated = true;
      }
      updatedResumePoints[rp.mediaId] = rp;
    });

    if (updated) {
      logger.log('RESUME POINT NEEDED UPDATING!!');
      // TODO dispatch event or something if anyone cares this updated
    }
    return updatedResumePoints;
  }

  _getResumePointKey({ watchable, programId, mediaId }) {
    if (XVP.getFeature(featuresTypes.xvpResumepoints)) {
      if (watchable.isRecording()) {
        return watchable.id;
      }
      return programId;
    }
    return mediaId || watchable.mediaId;
  }
}
export default new ResumePoints();
export { ResumePoints };
