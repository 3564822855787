/**
 * Constants for error categories
 *
 * @namespace constants.errorCategories
 */
export default {
  api: 'api',
  contentRestricted: 'content_restricted',
  geofenceError: 'geofence_error',
  heartbeat: 'heartbeat',
  offline: 'offline',
  outputRestricted: 'output_restricted',
  player: 'player',
  provision: 'provision',
  subscriptionError: 'subscription_error',
  unsupportedDevice: 'unsupported_device'
};
