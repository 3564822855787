/**
 * Constants for Player and PlayerPlaform Events
 *
 * @namespace constants.playerEvents
 */
export default {
  adProgress: 'AD_PROGRESS',
  adError: 'AD_ERROR',
  adComplete: 'AD_COMPLETE',
  adExited: 'AD_EXITED',
  adStart: 'AD_START',
  adBreakComplete: 'AD_BREAK_COMPLETE',
  adBreakExited: 'AD_BREAK_EXITED',
  adBreakStart: 'AD_BREAK_START',
  adHealing: 'AD_HEALING',
  attempt: 'attempt',
  stateChange: 'state-change',
  bitrateChange: 'BITRATE_CHANGE',
  bitrateChanged: 'BITRATE_CHANGED',
  bufferComplete: 'BUFFER_COMPLETE',
  bufferStart: 'BUFFER_START',
  contentGap: 'CONTENT_GAP',
  droppedFPSChanged: 'DROPPED_FPS_CHANGE',
  drmMetadata: 'DRM_METADATA',
  durationChanged: 'DURATION_CHANGED',
  emergencyAlertComplete: 'EMERGENCY_ALERT_COMPLETE',
  emergencyAlertFailure: 'EMERGENCY_ALERTY_FAILURE',
  emergencyAlertStarted: 'EMERGENCY_ALERT_STARTED',
  fpsChanged: 'FPS_CHANGED',
  fragmentInfo: 'FRAGMENT_INFO',
  licenseAcquired: 'LICENSE_ACQUIRED',
  mediaFailed: 'MEDIA_FAILED',
  mediaEnded: 'MEDIA_ENDED',
  mediaOpened: 'MEDIA_OPENED',
  mediaOpenedDelayed: 'MEDIA_OPENED_DELAYED',
  mediaRetry: 'MEDIA_RETRY',
  mediaProgress: 'MEDIA_PROGRESS',
  mediaWarning: 'MEDIA_WARNING',
  mediaError: 'MEDIA_ERROR',
  mediaKeyStatus: 'MEDIAKEY_STATUS',
  missingDRMToken: 'MISSING_DRM_TOKEN',
  audioStreamsChanged: 'NUMBER_OF_ALTERNATIVE_AUDIO_STREAMS_CHANGED',
  captionsStreamsChanged: 'NUMBER_OF_ALTERNATIVE_CLOSED_CAPTIONS_STREAMS_CHANGED',
  playerPlatformReady: 'PLAYER_PLATFORM_READY',
  playStateChanged: 'PLAY_STATE_CHANGED',
  playbackStarted: 'PLAYBACK_STARTED',
  playerReady: 'PLAYER_READY',
  seekStart: 'SEEK_START',
  seekComplete: 'SEEK_COMPLETE',
  streamBoundary: 'STREAM_BOUNDARY',
  vpaidAdEvent: 'VPAID_AD_EVENT',
  streamSwitch: 'STREAM_SWITCH'
};
