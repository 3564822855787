import api from 'api';
import featuresTypes from 'constants/xvp-features';
import errorCategories from 'constants/error-categories';
import watchableTypes from 'constants/watchable-types';

import ListingModel from 'model/listing';
import WatchableModel from 'model/watchable';
import SplunkLogger from '../lib/telemetry/splunk-logger';
import { getProperty, selectVchipRating } from 'lib/helpers';
import XVP from '../lib/xvp';
import { senderDebugger } from '../lib/debug/sender-receiver-debug';

let channelMapPromise = null;
let channelCache = {};
const localChannels = [];

/**
 * Channel object
 *
 * @hideconstructor
 */
class ChannelModel extends WatchableModel {
  listingCache = {};
  /**
   * Load the channel map
   *
   * Response is cached, so subsequent calls will not make a network request.
   * @async
   * @return {HypergardResource[]}
   */
  static async getChannelMap() {
    const isXVP = XVP.getFeature(featuresTypes.xvpTVGrid);
    if (!channelMapPromise) {
      if (isXVP) {
        const channelsCacheKey = getProperty(XVP.session, 'xvpSession.cachePolicies.xrn:cache:policy:linear:availableChannels');

        const localTvGridChannels = await XVP.send({
          endPoint: 'getLocalTvGridChannels',
          f2m: 'include',
          include: 'subscriberAccess'
        });

        channelMapPromise = await XVP.send({
          endPoint: 'getChannelsByCacheKey',
          queryParams: {
            cacheKey: encodeURIComponent(channelsCacheKey)
          }
        }).then((channels) => {
          if (channels) {
            return (localTvGridChannels||[]).length ? channels.concat(localTvGridChannels) : channels;
          }
        });
        return channelMapPromise;
      }

      channelMapPromise = api.send({
        endpoint: 'getChannelMap',
        params: {
          freetome: 'off' // TODO: Do we need to be able to change this?
        }
      })
        .then((response) => {
          const channelsSource = response.resource.getEmbedded('channels');
          return channelsSource;
        })
        .catch((error) => {
          SplunkLogger.onError({ error: (error.xhr && error.xhr.xtv) || error.xhr || error });
          channelMapPromise = null;
          throw error;
        });
    }

    return channelMapPromise;
  }

  /**
   * Reset caches
   *
   * Resets the channel map cache
   */
  static resetCache() {
    channelMapPromise = null;
    channelCache = {};
  }

  /**
   * Load channel based on channel ID
   * @async
   * @param {string} channelId - Channel ID to load
   * @return {ChannelModel}
   */
  static async load(channelId) {
    this.channelId = channelId.toString();
    const isXVP = XVP.getFeature(featuresTypes.xvpTVGrid);

    if (!channelCache[channelId]) {
      const channels = await ChannelModel.getChannelMap();
      let channelResource;
      senderDebugger.sendDebugMessage( 'LOAD Channel isXVP', {
        isXVP: isXVP,
        channelCount: channels && channels.length }
      );
      if (channels.length > 0 && isXVP) {
        channelResource = channels.find((ch) => ch.channelId.toString() === channelId);
      } else {
        channelResource = channels.find((ch) => ch.getProp('channelId') === channelId);
        if (!channelResource) {
          console.warn(`Attempting to find channelId=${ channelId } in local channel map`);
          const localChannel = await ChannelModel.getLocalChannelById(channelId);
          if (localChannel) {
            channelResource = channels.find((ch) => ch.getProp('companyId') === localChannel.companyId && ch.getProp('isTve'));
          }
        }
      }

      if (!channelResource) {
        senderDebugger.debugErrorMessage(`[XVP][Channel][Load] Cannot find channelId=${ channelId } in channel map`, {
          channelId: channelId,
          channelMapCount: channels && channels.length
        });

        throw ({
          category: errorCategories.player,
          error: {
            module: 'Video',
            code: 'channelNotFound',
            description: `Cannot find channelId: ${channelId} within the channel map`
          }
        });
      }

      channelCache[channelId] = await ChannelModel.fromResource(channelResource);
    }

    return channelCache[channelId];
  }

  /**
   * Load channel based on channel self link
   * @async
   * @param {string} url - Self link URL to search for
   * @return {ChannelModel}
   */
  static async loadFromSelfLink(url) {
    senderDebugger.sendDebugMessage(`loadFromSelfLink for url: ${url}`);
    const channels = await ChannelModel.getChannelMap();
    // Parses only hyperguard resource - not XVP supported parsing
    const channelResource = channels.find((ch) => ch.getFirstAction('self').getRawActionUrl() === url);
    const channelId = channelResource.getProp('channelId');
    senderDebugger.sendDebugMessage(`[Channel] loadFromSelfLink Found channel: ${channelId}`, {
      channelId: channelId,
      channelResource: channelResource,
      channelCache: `channelCache count: ${Object.keys(channelCache).length}`,
      channelExistsInCache: channelCache[channelId]
    });
    if (!channelCache[channelId]) {
      channelCache[channelId] = await ChannelModel.fromResource(channelResource);
    }

    return channelCache[channelId];
  }
  /**
   * loadFromChannelId - XVP method - load a channel id from channel map
   * @param {object} jsonResource  - json from XVP channel map
   * @return {ChannelModel}
   *
   **/
  static async loadFromChannelId(jsonResource = {}) {
    const channelId = jsonResource.channelLinkId && jsonResource.channelLinkId.split('_')[1];

    if (!channelCache[channelId]) {
      channelCache[channelId] = await ChannelModel.fromResource(jsonResource);
    }

    return channelCache[channelId];
  }

  static async _propertiesFromResource(resource) {
    if (!resource.getProps) {
      return await ChannelModel._propertiesFromXvp(resource);
    }

    const props = resource.getProps();
    return {
      ...await super._propertiesFromResource(resource),
      companyCallSign: props['branchOf/company/callSign'],
      callSign: props.callSign,
      channelId: props.channelId,
      entitled: props.entitled,
      streamId: props.streamId,
      isTveFlag: props.isTve,
      number: props.number,
      enforceParentalControlsOnChannel: props.enforceParentalControlsOnChannel,
      rating: props['contentRating/detailed'],
      isAdult: props.isAdult,
      stationId: props.stationId,
      companyId: props.companyId,
      logo: resource.getFirstAction ? resource.getFirstAction('logo') : '',
      contentProvider: {
        name: props.callSign
      },
      tveVariant: resource.getFirstAction ? resource.getFirstAction('tveVariant').getRawActionUrl() : 'not yet'
    };
  }

  static async _propertiesFromXvp(props) {
    const locators = props.locators || [];
    const station = props.station || {};
    const companyAssociations = station && station.companyAssociations || [];
    const playableStream = locators.find((stream) => stream.format === 'HLS') || locators[0];
    const productContexts = station.productContexts || [];
    const externalStream = locators && locators[0] && locators[0].externalStream;

    senderDebugger.sendDebugMessage('[XVP][CHANNEL][_propertiesFromXvp] props',
      { props: JSON.stringify( props, undefined, 4),
        locators,
        station,
        companyAssociations,
        playableStream,
        productContexts,
        externalStream
      }
    );
    let isTve = false;
    {
      const locator = locators && locators[0];
      isTve = externalStream ||
       locator && locator.namespaceTitle && locator.namespaceTitle.endsWith('TVE') ||
       !!productContexts.find((context) => context.type === 'TVE');
    }

    return {
      callSign: props.onScreenCallsign,
      castInfo: {
        chromecast: true
      },
      channelId: props.channelId,
      channelLinkId: `link_${props.channelId}`,
      companyCallSign: companyAssociations.length && companyAssociations[0].displayName,
      companyId: companyAssociations.length && companyAssociations[0].companyId,
      entitled: station.subscriberAccess ? station.subscriberAccess.isWatchable : false,
      isTveFlag: isTve,
      logo: `entityId=${station.stationId}`,
      number: props.channelNumber,
      playableStream,
      productContexts: productContexts,
      stationId: station.stationId,
      streamId: playableStream.streamId,
      streams: locators,
      contentProvider: {
        name: props.onScreenCallsign
      },
      tveVariant: station.stationId,
      _type: watchableTypes.Channel
    };
  }

  /**
   * Build channel from Hypergard resource
   *
   * @param {object} resource - Hypergard resource
   * @return {ChannelModel}
   */
  static async fromResource(resource) {
    if (resource.getFirstAction) {
      return new ChannelModel(await ChannelModel._propertiesFromResource(resource));
    }
    senderDebugger.sendDebugMessage(`[XVP][CHANNEL][fromResource] resource ${Object.keys(channelCache).length}`,
      { fromResource: resource }
    );
    return new ChannelModel(await ChannelModel._propertiesFromXvp(resource));
  }

  /**
   * Get listing for a specific time
   *
   * Returns the listing that is airing at the specified time
   *
   * @param {number} time - Time to get listing for
   * @return {ListingModel}
   */
  async getListingAt(time) {
    let listingResources = null;
    const isXVP = XVP.getFeature(featuresTypes.xvpTVGrid);
    const timeStart = Math.floor(time / 3600e3) * 3600e3; // Round time down to nearest hour
    const listingFinder = (listing) => (listing.startTime <= time && listing.endTime > time);

    if (!this.listingCache[timeStart]) {
      if (isXVP) {
        const stationParamObj = Object.assign({}, {
          endPoint: 'getListingsByDate',
          pathParams: {
            stationId: this.stationId
          },
          queryParams: {
            date: new Date(timeStart).toISOString().split('T')[0]
          }
        });

        const listingResponse = await XVP.send(stationParamObj);

        listingResources = (listingResponse || []).map((listing) => ({
          channelLinkId: `link_${this.channelId}`,
          duration: listing.duration,
          endTime: new Date(listing.endTime).getTime(),
          isHD: listing.videoQuality === 'HD',
          isXVP: true,
          listingId: listing.listingId,
          program: listing.program,
          rating: selectVchipRating(listing.contentRatings || []),
          streams: this.streams,
          startTime: new Date(listing.startTime).getTime(),
          title: listing.title,
          _type: watchableTypes.Listing
        }));
      } else {
        const response = await api.send({
          endpoint: 'getTvGridChunk',
          params: {
            startTime: timeStart,
            hours: 1,
            channelIds: this.accountChannelId || this.channelId
          }
        }).catch((error) => {
          senderDebugger.debugErrorMessage(`[XTVAPI][Channel][GetListingAt] ERROR getTvGridChunk station: ${this.stationId}`, error);
          SplunkLogger.onError({ error: (error.xhr && error.xhr.xtv) || error.xhr || error });
          throw error;
        });

        const channel = response.resource.getEmbedded('channels')[0];
        listingResources = channel.getEmbedded('listings');
      }

      this.listingCache[timeStart] = await Promise.all(listingResources.map(ListingModel.fromResource));
      this.listingCache[timeStart].forEach((listing) => listing.channel = this);
    }
    senderDebugger.debugNetworkMessage('[CHANNEL][getListingAt] from the listingCache: ', {
      listingCache: this.listingCache[timeStart].length
    });

    return this.listingCache[timeStart].find(listingFinder);
  }

  static async getLocalChannelMap(networkAffiliateCompanyId = null) {
    let params = null;
    if (networkAffiliateCompanyId) {
      params = { networkAffiliateCompanyId };
    }

    const localChannelResponse = await api.send({
      endpoint: 'getLocalChannelMap',
      params
    });

    if (!localChannelResponse) {
      return;
    }

    const localChannelResources = localChannelResponse.resource.getEmbedded('channels');
    if (localChannelResources && localChannelResources.length) {
      localChannelResources.forEach((channelResource)=> {
        const localStreamResources = channelResource.getEmbedded('stream');
        const localStreams = [];

        localStreamResources.forEach((stream)=> {
          localStreams.push(Object.assign(stream.getProps(), {
            contentUrl: stream.getFirstAction('contentUrl').getActionUrl('self')
          }));
        });

        localChannels.push(Object.assign(channelResource.getProps(), { streams: localStreams }));
      });
    }
    return localChannels;
  }

  static async getLocalChannelById(channelId) {
    let localChannel = localChannels.find((ch) => ch.channelId === channelId);
    if (!localChannel) {
      const localChannelList = await ChannelModel.getLocalChannelMap();
      localChannel = localChannelList.find((ch) => ch.channelId === channelId);

      if (!localChannel) {
        console.warn(`Cannot find channelId=${ channelId } in local channel map`);
        return;
      }
    }
    return localChannel;
  }

  async getLocalChannelByCompanyId() {
    const companyId = this.companyId || this.channel.companyId;
    let localChannel = localChannels.find((ch) => ch.companyId === companyId);

    if (!localChannel) {
      const localChannelList = await ChannelModel.getLocalChannelMap(companyId);
      localChannel = localChannelList.find((ch) => ch.companyId === companyId);

      if (!localChannel) {
        console.error(`Cannot find companyId=${ companyId } in local channel map`);
        return;
      }
    }
    return localChannel;
  }

  hasGeofencedLocatorType() {
    const locators = this.locators || this.streams || [];

    if (locators.length) {
      return !!locators.find((locator) => locator.type === 'GeoFenced');
    }
    return false;
  }

  canStreamTve(servicePostalCode = '') {
    const playableStream = this.playableStream || this.findPlayableStream() || {};
    const serviceZoneType = (playableStream || {}).serviceZoneType || '';
    const serviceZone = serviceZoneType &&
    serviceZoneType.includes('zipcode') &&
    serviceZoneType + ':' + servicePostalCode;

    return XVP.send({
      endPoint: 'canStreamTve',
      pathParams: {
        streamId: this.streamId || ''
      },
      queryParams: {
        serviceZone,
        locatorType: playableStream.format || playableStream.encodingFormat || ''
      }
    });
  }
}

WatchableModel.addType(ChannelModel, ({ _type }) =>
  _type === watchableTypes.Channel);

export default ChannelModel;
